<template>
  <div style="background: #efefef;">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="box">
            <img src="@/assets//images/comment_face_1.png" alt />
            <div>
              <p>我是做配件批发的，用了简单客的维修管理系统后，效率提高很多，可以区分进货批次、库存预警、主要是每月统计利润很清晰，还包含进销存的功能，希望软件持续更新。</p>
              <h4>赵先生 来自深圳</h4>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="box">
            <img src="@/assets//images/comment_face_1.png" alt />
            <div>
              <p>平时用简单客的出纳收支软件，记录单位的日常收支，软件很很很很很简单，但是很很很很很好用，哈哈。</p>
              <h4>周女生 来自福建</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'commSay',
  data() {
    return {}
  },
  watch: {},
  computed: {},
  created() {},
  methods: {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 576px) {
  .container {
    padding-top: 5px !important;
    .box {
      padding: 20px 5px !important;
      img {
        width: 56px !important;
        margin-right: 10px !important;
      }
      p {
        font-size: 14px !important;
        line-height: 20px !important;
      }
      h4 {
        margin-top: 5px !important;
        font-size: 16px !important;
        padding-bottom: 0 !important;
      }
    }
  }
}
p {
  margin-bottom: 0;
}
.container {
  padding-top: 20px;
  padding-bottom: 0;
  background: url('../assets/images/background_world.jpg') center;
  background-size: cover;
  .box {
    display: flex;
    align-items: flex-start;
    padding: 30px 0;
    img {
      float: right;
      width: 96px;
      margin-right: 15px;
      border-radius: 50%;
      border: 3px solid white;
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }
    h4 {
      margin-top: 15px;
      padding-bottom: 10px;
      font-size: 16px;
      text-align: right;
    }
  }
}
</style>
<template>
  <div>
    <div class="app-top">
      <swiper-list :dataList="swiperList" :height="500" :interval="4000"></swiper-list>
    </div>
    <moduleTitle title="产品&服务" />
    <comm-service />
    <soft-list :btnDownload="true" :btnBuy="false" />
    <comm-say />
    <comm-desc />
  </div>
</template>

<script>
import swiperList from '@/components/swiperList'
import commService from '@/components/commService'
import moduleTitle from '@/components/moduleTitle'
import commSay from '@/components/commSay'
import commDesc from '@/components/commDesc'
// import commFaq from '@/components/commFaq'
import softList from '@/components/softList'
export default {
  name: 'homeIndex',
  components: {
    swiperList,
    commService,
    moduleTitle,
    commSay,
    commDesc,
    // commFaq,
    softList
  },
  data() {
    return {
      dataList: [],
      swiperList: [
        {
          image: require('@/assets/images/swiper_REPAIR.png'),
          title: '小店也能用的软件！',
          subTitle: '手机、电脑数据实时同步',
          backgroundColor: 'linear-gradient(rgba(100,100,100,0.5), rgba(100,100,100,0.5))',
          backgroundImage: require('@/assets/images/bg/bg-12.jpg'),
          buttons: [
            {
              color: 'primary',
              text: '下载试用',
              href: '#/download'
            },
            {
              color: 'info',
              text: '购买正版',
              href: '#/buy'
            }
          ]
        },
        {
          image: '',
          title: '合作共赢 共创财富',
          subTitle: '面向全国诚招合伙人，期待志同道合的你！',
          backgroundColor: 'linear-gradient(rgba(100,100,100,0.5), rgba(100,100,100,0.5))',
          backgroundImage: require('@/assets/images/bg/bg-9.jpg'),
          buttons: [
            {
              color: 'success',
              text: '立即加入',
              href: '/#/agent'
            }
          ]
        }
      ]
    }
  },
  watch: {},
  computed: {},
  created() {
    this.utils.refreshSoftCates()
    //保存邀请码
    this.onloadArgs.saveInvite(this.$route.query)
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 576px) {
}

.app-top {
  margin-top: 58px;
}
</style>
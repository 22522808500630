<template>
  <!-- commService,commSay,commDesc,commFaq ,moduleTitle-->
  <div>
    <div class="container">
      <div class="row">
        <div class="col-sm-3" v-for="(item,index) in dataList" :key="index">
          <div class="box">
            <h3>{{item.title}}</h3>
            <p>{{item.text}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'commService',
  data() {
    return {
      dataList: [
        {
          title: '简洁易用',
          text: '功能简介明了、化繁为简、上手即用。'
        },
        {
          title: '持续维护',
          text: '专业研发团队、跟随用户需求、持续维护更新（手机版即将发布）。'
        },
        {
          title: '免费升级',
          text: '所有客户、享受终身免费升级服务。'
        },
        {
          title: '效率提升',
          text: '高效便捷、管理体验，经营利润一目了然。'
        }
      ]
    }
  },
  watch: {},
  computed: {},
  created() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 576px) {
  .box {
    height: 120px !important;
    h3 {
      margin-top: 0 !important;
    }
    p {
      margin-top: 5px !important;
    }
  }
}
p {
  margin-bottom: 0;
}
.box {
  background-color: #fff;
  box-shadow: 0px 0px 26px rgb(206 206 206 / 16%);
  padding: 15px;
  height: 150px;
  margin-bottom: 10px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: center;
  position: relative;
  transition: all 0.3s;
  top: 0;
  h3 {
    font-size: 22px;
    line-height: 30px;
    color: #333;
    margin-top: 10px;
  }
  p {
    text-align: center;
    font-size: 14px;
    color: #999999;
    line-height: 28px;
    margin-top: 15px;
  }
}
</style>